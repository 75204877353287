import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "get-account"
    }}>{`Get Account`}</h1>
    <p>{`Returns an Account.`}</p>
    <p><strong parentName="p">{`GET`}</strong>{` `}<inlineCode parentName="p">{`/account/get`}</inlineCode></p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account's API Key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account ID of the Account to retrieve.`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Example Response`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Result": "1",
  "Error_Messages": {},
  "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
  "Data": {
    "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
    "Account_Number": "A-LSE-210-M4K",
    "Account_Type": "Trial",
    "Account_Status": "Active",
    "Account_Name": "My Business Name",
    "Account_Phone": "999-123-1234",
    "Account_Email": "Email@mybusiness.com",
    "Account_St_Address": "1234 Address Dr",
    "Account_City": "City",
    "Account_State": "State",
    "Account_Zip": "54321",
    "Created_UTC_DateTime": "2021-10-03 15:12:42"
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      